import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Digital = () => (
  <div className={`bg-gray-700`}>
  <Layout>
    <SEO title="Digital Policy" />
      <section className={`container px-5 mx-auto mb-20`}>
        <div className={`flex flex-wrap text-white`}>
        {/* <!-- intro-content --> */}
          <div className={`w-full sm:px-10 sm:mx-16 `}>
            <h1 className={`mb-2 font-hairline  md:border-b-0 lg:border-b-2 text-2xl md:text-3xl lg:text-4xl sm:text-center sm:leading-none sm:pb-3 xl:leading-tight lg:text-left`}>Digital Policy</h1>
            <div className={``}>
              <h3>Email</h3>
              <p>
                I am not currently using an e-mail encryption programme, so any emails we send to each other may be vulnerable to viruses or human error. For this reason to protect confidentiality, I do not engage in therapy by email and ask that we keep email/text communication to a minimum and only to confirm arrangements such as rescheduled appointment times.If you choose to communicate with me by email, be aware that all emails are retained in the logs of Internet Service Providers. Furthermore, they can be vulnerable to viruses and unintended forwarding or replication. If you are concerned about the confidentiality of your emails, you may wish to contact me by telephone instead. I check emails only during office hours and not weekends. I endeavour to respond to all emails within 24 hours when I am not away. Emails should never be used in the event of an emergency, in which case you should contact emergency services.
              </p>

              <h3>E-Invoicing</h3>
              <p>
                If you request an invoice for your accounting purposes, I use an online software application to process my invoices that are then sent out to your chosen e-mail address. If you would prefer not to receive these invoices by email, please let me know and I can bring you a printed copy at the beginning of each calendar month.
              </p>

              <h3>SMS</h3>
              <p>
                You may feel free to contact me by text message to alert me if you are running late for a session or for similar reasons. However, because of the lack of context of text messages, it is generally not the best method for communicating with me about more important matters, so please do phone and leave me a message. As a private practitioner I am unable to offer an emergency service, even by phone. Should you experience an emergency please contact either the emergency services or if you are feeling suicidal, ring The Samaritans: 116 123. If you feel that this may be an issue, please discuss it with me.
              </p>

              <h3>Telephone and Skype</h3>
              <p>
                While I do not generally run my practice remotely, there may be times with existing clients where we have a session by telephone or video conferencing, for example, if weather conditions make it necessary. Any such sessions will be discussed in advance. I make every effort to choose video conferencing software that is recognised to be adequately encrypted – so this may involve you downloading certain software or logging into a web-based application. When engaging via video conferencing, we both agree not to use any recording devices for remote sessions. It is also crucial that you’re sure your environment from which you are conferencing with me is safe and secure. We will discuss these details should we decide to work in this manner.
              </p>

              <h3>Digital Policy</h3>
              <p>
                  As well as being a supervisor and therapist, I am a tutor and have written articles and on occasion have been consulted by media. As part of this work, I maintain an active social media presence. Online resources such as Twitter enable me to communicate psychological concepts or promote events. I hold my duty of care and confidentiality to my psychotherapy clients and supervisees as sacrosanct and consider any media work to be related to, but completely separate from my clinical work.
              </p>

              <h3>Social Media</h3>
              <p>
                I maintain a public Twitter and LinkedIn profile and website as a way of promoting psychological ideas through the media, and to make people aware of workshops, conferences, or other events that I may be taking part in, or that might be of interest. I frequently send across relevant links and sometimes engage in public conversations on these topics. Despite my online presence, I would prefer that our relationship remain as much as possible between us in the consultation room, therefore I will not knowingly follow any current or former clients on these social media platforms. Whether you follow me or not is of course to your discretion, though I would suggest for reasons of confidentiality and preserving our therapeutic relationship that it’s advisable not to. I, like many others, maintain a social media presence. It would not be appropriate to be ‘friends’ with former or current clients.
              </p>

              <p className={`mb-5`}>
                I acknowledge that there can be some overlap across social networks, and that this sometimes comes to light. If this were the case, it would be something we would need to discuss in session. As the above, I have decided that it would not be appropriate to add current or former clients as “connections” on Linked-In.
              </p>

              <h3>Google</h3>
              <p>
                It is likely that you will have Googled me before you contacted me. While I acknowledge that Googling others is now a generally accepted aspect of daily life, I wish to avoid encountering information about you that does not come directly from you. In general practice I do not Google my clients before I meet them. It is of course your right to Google me. However, if in your search some questions are raised, I would request that you raise them with me at the first opportunity.
              </p>
              <p className={`font-bold`}>
                Thanks to Dr Aaron Balick who has made his digital policy available to therapists for use.
              </p>
            </div>
          </div>
        </div>
      </section>
    {/* </div> */}
    </Layout>
    </div>
)

export default Digital
